
<template>
  <div class="pb-5">
    
    <div class="vx-row">
      <div class="vx-col w-1/5 mb-5" v-for="(kat, index) in layananGroup" :key="index">
        <vx-card card-background="primary" content-color="#fff" class="w-50 text-center" @click="toLayanan(kat)">
          <img :src="getImage('/term/'+kat.icon)" :alt="kat.name" class="responsive px-5">
          <div class="text-white flex flex-col justify-between" style="height:5rem;">
            <h3 class="text-white mb-4">{{kat.name}}</h3>
          </div>
        </vx-card>
      </div>
    </div>

    <!--
    <div class="carousel-example mt-10">
      <div class="swiper-inner">
        <swiper :options="swiperOption" class="px-12">
        
          <swiper-slide class="text-center" v-for="(kat, index) in layananGroup" :key="index">
            <vx-card card-background="primary" content-color="#fff" class="w-50 text-center" @click="toLayanan(kat)">
              <img :src="getImage('/term/'+kat.icon)" :alt="kat.name" class="responsive px-5">
              <div class="text-white flex flex-col justify-between mt-4" style="height:5rem;">
                <h2 class="text-white mb-4">{{kat.name}}</h2>
              </div>
            </vx-card>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    -->

    <!--
    <h2 class="mb-5 mt-base">Update</h2>
    <div class="flex justify-center mx-auto">
      <div class="flex justify-center text-center w-1/3">
        <vs-button size="large" radius color="warning" type="filled" icon-pack="feather" icon="icon-layout" @click="$router.push('/post/berita').catch(() => {})" text-color="rgb(30,30,30)" svgClasses="w-16 h-20"></vs-button>
      </div>
      <div class="flex justify-center text-center w-1/3">
        <vs-button size="large" radius color="warning" type="filled" icon-pack="feather" icon="icon-sun" @click="$router.push('/post/wawasan').catch(() => {})" text-color="rgb(30,30,30)" svgClasses="w-16 h-20"></vs-button>
      </div>
      <div class="flex justify-center text-center w-1/3">
        <vs-button size="large" radius color="warning" type="filled" icon-pack="feather" icon="icon-aperture" @click="$router.push('/post/pengumuman').catch(() => {})" text-color="rgb(30,30,30)" svgClasses="w-16 h-20"></vs-button>
      </div>
    </div>
    <div class="flex justify-center mx-auto mt-2">
      <div class="flex justify-center text-center w-1/3">
        <small class="text-small">Berita</small>
      </div>
      <div class="flex justify-center text-center w-1/3">
        <small class="text-small">Wawasan</small>
      </div>
      <div class="flex justify-center text-center w-1/3">
        <small class="text-small">Pengumuman</small>
      </div>
    </div>
    -->
    
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
//import moduleHome from '@/stores/home/moduleHome.js'
//import moduleTask from '@/stores/task/moduleTask.js'

export default {
  data () {
    return {
      swiperOption: {
          slidesPerView: 1,
          spaceBetween: 30,
          //centeredSlides: true,
          //freeMode: true,
          pagination: {
              el: '.swiper-pagination',
              clickable: true
          }
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    layananGroup() {return this.$store.state.layanan_group}
  },
  created () {
    //this.$vs.loading()
    //this.$store.registerModule('home', moduleHome)
    //this.$store.registerModule('task', moduleTask)
    //this.$store.dispatch('home/layanangroupFetch')
    /*
    setTimeout(() => {
      this.$vs.loading.close()
    }, 2000)
    */
    this.$store.commit('navbarUpdateStatus', false)
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    //this.$store.unregisterModule('home')
  },
  methods: {
    toLayanan (tasks) {
      this.$router.push({
        name: "Layanan", //use name for router push
        params: { tasks }
      });
    }
  }
}
</script>

<style scoped>
.vx-card {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
  -webkit-transition: all .3s ease-in-out;
}
/*
.swiper-inner {
    position: relative;
    overflow: hidden;
    height: 330px;
    padding: 15px;
}

.swiper-container {
    width: 300px !important;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}
*/
</style>
